/**
 * Adds a spinner animation to the search button when clicked.
 */
const searchSpinner = () => {

	const submit         = document.getElementById( 'btnFetch' )
	const primarySubmit  = document.getElementById( 'PrimarySubmit' )
	const spinner = `<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Searching...`

	if ( submit ) {
		submit.addEventListener( 'click', event => {
			submit.innerHTML = spinner;
		} );
	}

	if ( primarySubmit ) {
		primarySubmit.addEventListener( 'click', event => {
			primarySubmit.innerHTML = spinner;
		} );
	}
}

// Used to modify the query string when updating the property archive sort function.
const updateQueryStringParameter = ( uri, key, value ) => {
	let re = new RegExp( '([?&])' + key + '=.*?(&|$)', 'i' )
	let separator = uri.indexOf( '?' ) !== - 1 ? '&' : '?'
	if ( uri.match( re ) ) {
		return uri.replace( re, '$1' + key + '=' + value + '$2' )
	} else {
		return uri + separator + key + '=' + value
	}
}

/**
 * Get the public property documents for a listing from Rex's API.
 *
 * @returns {Promise<void>}
 * @param rexID The listing Rex ID.
 */
const getRexDocuments = async ( rexID ) => {

	const args = {
		method: 'POST',
		body: 'action=get_rex_document_data&listing_id=' + rexID + '&ajax-nonce=' + docs_ajax.nonce,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
	}
	const response = await fetch( docs_ajax.url, args )
	const docs     = await response.json()

	if ( docs ) {

		const dropdown = document.getElementById( 'loading-contract' )
		let links = '';

		for ( const [key, value] of Object.entries( docs ) ) {

			let doc_title = value.description.split( ' -' )

			if ( doc_title[0].toLowerCase().includes( 'title' ) ) {
				doc_title[0] = 'Title Search'
			}

			links = links + `
                <a class="dropdown-item px-3" href="${value.url}" target="_blank" rel="noopener">
                    ${doc_title[0]}
                </a>`
		}
		dropdown.parentElement.innerHTML = links
	}
}

module.exports = { searchSpinner, updateQueryStringParameter, getRexDocuments }